@import '../../../styles/variables.module.scss';

.login-form-wrapper {
  text-align: center;
  margin: auto;
  font-family: 'Roboto';
}

.heading {
  font-size: 27px;
  letter-spacing: 0;
  font-weight: 700;
  line-height: 1.2;

  &.no-bold {
    font-weight: normal;
  }
}

.subHeading {
  font-size: 15px;
  letter-spacing: 0.02px;
  color: #b2b2b2;
  margin-top: 8px;
  padding: 0 16px;
  line-height: 1.3;
}

.user-form {
  width: 100%;
}

.bar-main-div {
  margin-top: 30px;
}

.login-submit-btn {
  margin-top: 10px;
  margin-bottom: 12px;
}

.login-submit-btn-google {
  margin-bottom: 12px;
}

.wrap-label {
  max-width: 400px;
  width: 100%;
}

.wrap-label-user {
  margin: 116px 0 100px 0;
  max-width: 400px;
  width: 100%;
  position: relative;
}

.label {
  text-align: left;
  margin-bottom: 1px;
  font-size: 13px;
}

.wrap-phone-code {
  display: flex;
  height: 48px;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
}

.input-field {
  color: #262626;
  border: none;
  padding: 0 8px;
  letter-spacing: 0.5px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  outline: none;
  font-size: 17px;
  width: 100%;
}

.input-field-center {
  border: none;
  padding: 0 8px 0 8px;
  letter-spacing: 0.5px;
  border-radius: 6px;
  outline: none;
  font-size: 16px;
  width: 100%;
}

.phone-code {
  width: 45px;
  height: 48px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 2.5px;
  border-right: 1px solid #f7f7f7;
  padding: 8px 24px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  letter-spacing: 0;
}
.email-code {
  height: 48px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 10px 12px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.form-item-shadow {
  border: none;
  height: 40px;
  background: #fff;
  box-shadow: 0 3px 6px #b1d6f8b3;
  border-radius: 5px;
  display: flex;
  margin: 100px 0;
}

.otp-time-main-div {
  margin: 10px auto;

  div {
    color: #707070;
    font-size: 12px;
    margin-bottom: -16px;
    display: flex;
    justify-content: center;
  }
}

.otp-resend {
  margin-top: 15vh;
  display: flex;
  justify-content: center;
  position: relative;

  img {
    width: 22px;
    height: 22px;
    margin-top: 38px;
    margin-left: -28px;
    cursor: pointer;
  }
}

.terms-condition {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin: auto;
  align-items: center;
  margin-top: 10px;

  :global(.ant-checkbox-inner) {
    border-color: #13223f;
    :hover {
      border-color: #13223f;
    }
  }

  :global(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #13223f;
    border-color: #13223f;
    height: 15px;
  }

  p {
    color: #000;
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 0;
  }
}

.blue-link {
  margin: 8px 0;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  color: #19b4c8;
  cursor: pointer;
  text-align: end;
}

.resend-otp-text {
  margin: 8px 0;
  font-size: 12px;
  text-align: left;
  color: #000;
  opacity: 0.7;
}

.otp-container {
  display: flex;
  justify-content: space-between;
}

.otp-input {
  width: 48px;
  height: 48px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #707070;

  &:focus {
    outline: none;
    border: 1px solid #72a3aa;
    font-size: 16px;
  }
}

.email-login {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 12px;
  color: #14213d;
  font-weight: 500;
}

.google-btn {
  background-color: transparent;
  border: none;
  height: 34px;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #19b4c8;
}

.inp-below-text {
  font-size: 11px;
  opacity: 0.6;
  text-align: left;
  padding-left: 5px;
  padding-top: 3px;
}

.vertical-line {
  border-right: none;
  border-bottom: 1px dashed lightgrey;
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 15px;
}

.or-write {
  background-color: #fff;
  width: 50px;
  height: 22px;
  position: relative;
  left: 0;
  font-size: 13px;
  color: #3c465c;
  top: 12px;
  text-align: center;
  font-weight: 500;
}

.logo-container {
  display: flex;
  justify-content: space-between;
  width: 130px;
  margin: auto;
  margin-bottom: 22px;
}

.google-logo {
  width: 16px;
  margin-right: 10px;
}

.fb-logo {
  width: 50px;
}

.otp-request {
  font-size: 13px;
  color: #13223f;
  margin-top: 8px;
}

.cursor-blue-link {
  cursor: pointer;
  color: #14213d;
  text-decoration: underline;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;
  &:hover {
    color: #14213d;
  }
}

.brand-container {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 12px;
}

.brand-img {
  width: 264px;
  height: 60px;
  object-fit: contain;
  margin-top: 16px;
}

.wrapper-container {
  padding: 12px 14px;
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc80;
  border-radius: 12px;
}

.main-header {
  font-size: 18px;
  color: #14213d;
  font-weight: bold;
  margin-bottom: 6px;
  line-height: 0.7;
}
.sub-header {
  font-size: 18px;
  color: #14213d;
  margin-bottom: 20px;
}
.description-footer {
  margin-top: 10px;
  font-size: 14px;
  color: #14213d;
  margin-bottom: -5px;
}
.header-for-download {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #14213d;
}

.offers-container {
  background: #f1faff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 20px;
  font-size: 12px;
  color: #14213d;

  p {
    margin: 0px;
    span {
      font-weight: bold;
    }
  }
  p:nth-child(2) {
    color: #ea1800;
    cursor: pointer;
  }
}
.icon-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 7px #00000045;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 24px;
  }
}

.login-container {
  padding: 0px 24px;
}

.title {
  margin-top: 28px;
  color: #13223f;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
}
.sub-title {
  color: #556073;
  font-size: 16px;
  text-align: left;
}

.secure-transaction {
  font-size: 10px;
  font-weight: 600;
  color: #1d2226;
  margin-bottom: 2px;
}

.encryption-text {
  font-size: 10px;
  color: #707172;
  font-weight: 300;
  img {
    margin-left: 15px;
    width: 22px;
  }
}

.not-you {
  margin-top: 5px;
  font-size: 12px;
  color: #b10f15;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.footer-icons {
  display: flex;
  justify-content: space-between;
  padding: 10px 6px;

  @media screen and (max-width: 480px) {
    padding: 10px 3px;
  }
  img {
    max-width: 48px;
    max-height: 12px;
    filter: grayscale(1);
  }
}

@media (max-width: $breakpoint-md) {
  .wrap-label {
    max-width: 350px;
  }

  .otp-input {
    width: 38px;
    height: 38px;
    font-size: 17px;
  }
}

@media (max-width: $breakpoint-sm) {
  .sub-header {
    font-size: 16px;
  }

  .heading {
    font-size: 20px;
  }

  .subHeading {
    font-size: 14px;
  }

  .inp-below-text {
    font-size: 10px;
  }

  .wrap-label {
    max-width: 430px;
  }

  .google-btn {
    height: 34px;
  }

  .logo-container {
    width: 110px;
    margin-bottom: 24px;
  }

  .login-submit-btn {
    margin-top: 32px;
  }

  .vertical-line {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .google-logo {
    width: 30px;
  }

  .fb-logo {
    width: 40px;
  }

  .wrap-phone-code {
    height: 40px;
  }

  .phone-code {
    height: 38px;
  }
  .email-code {
    height: 40px;
    padding: 10px 0px 8px 8px;
  }
}
